import produce from "immer";
import { initialRegistrationStoreState, IRegistrationStoreState } from "store/state/registrationState";

import { ActionTypes, ActionTypesCollection } from "../actionTypes";

export default function registrationReducer(
  state: IRegistrationStoreState = initialRegistrationStoreState,
  action: ActionTypesCollection
): IRegistrationStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_ADDRESS:
      return produce(state, (draftState) => {
        draftState.address = { ...action.payload };
      });

    case ActionTypes.FETCHED_ADDRESS_ALREADY_REGISTERED:
      return produce(state, (draftState) => {
        draftState.zipCode.alreadyRegistered = action.payload;
      });
    case ActionTypes.FETCHED_ADDRESS_EXISTS:
      return produce(state, (draftState) => {
        draftState.zipCode.addressExists = action.payload;
      });
    case ActionTypes.FETCHED_OFFER:
      return produce(state, (draftState) => {
        draftState.calculation = { ...action.payload };
      });

    case ActionTypes.FETCHED_OFFER_ADVICE:
      return produce(state, (draftState) => {
        draftState.calculationAdvice = { ...action.payload };
      });

    case ActionTypes.FETCHED_ZIP_CODE_VALID:
      return produce(state, (draftState) => {
        draftState.zipCode.isZipCodeValid = action.payload;
      });

    case ActionTypes.UPDATED_DATA_CALCULATOR:
      return produce(state, (draftState) => {
        draftState.calculator = { ...action.payload };
      });

    case ActionTypes.UPDATED_DATA_CALCULATOR_CALCULATION:
      return produce(state, (draftState) => {
        draftState.calculator.calculation = { ...action.payload };
      });

    case ActionTypes.UPDATED_DATA_CALCULATOR_SELECTED_WEEKLY_HOURS:
      return produce(state, (draftState) => {
        draftState.calculator.calculation.selectedWeeklyHours = action.payload;
      });

    case ActionTypes.UPDATED_DATA_CALCULATOR_ADVICE:
      return produce(state, (draftState) => {
        draftState.calculatorAdvice = { ...action.payload };
      });

    case ActionTypes.UPDATED_DATA_CALCULATION_ADVICE_ESTIMATED_WEEKLY_HOURS:
      return produce(state, (draftState) => {
        draftState.calculationAdvice.estimatedWeeklyHours = action.payload;
      });

    case ActionTypes.UPDATED_DATA_PERSONAL_INFORMATION:
      return produce(state, (draftState) => {
        draftState.personalInformation = { ...action.payload };
      });

    case ActionTypes.UPDATED_DATA_SUMMARY:
      return produce(state, (draftState) => {
        draftState.summary = { ...action.payload };
      });

    case ActionTypes.UPDATED_ZIP_CODE_DATA:
      return produce(state, (draftState) => {
        draftState.zipCode = { ...action.payload };
      });

    case ActionTypes.UPDATED_DATA_CALCULATOR_VALID:
      return produce(state, (draftState) => {
        draftState.calculator.isValid = action.payload;
      });

    case ActionTypes.UPDATED_DATA_DISCOUNT_CODE:
      return produce(state, (draftState) => {
        draftState.calculator.calculation.discountCode = action.payload;
      });

    case ActionTypes.UPDATED_DATA_DISCOUNT:
      return produce(state, (draftState) => {
        draftState.calculator.discount = action.payload;
      });

    case ActionTypes.UPDATED_MINIMUM_HOURS_PER_FREQUENCY:
      return produce(state, (draftState) => {
        draftState.calculator.minimumPerFrequency = action.payload;
      });

    case ActionTypes.UPDATED_MODAL_CALCULATOR_ADVICE_SHOWN:
      return produce(state, (draftState) => {
        draftState.calculatorAdvice.isModalCalculatorAdviceShown = action.payload;
      });

    case ActionTypes.UPDATED_MODAL_SEND_CALCULATION_EMAIL_SHOWN:
      return produce(state, (draftState) => {
        draftState.isModalSendCalculationEmailShown = action.payload;
      });

    case ActionTypes.INITIALIZE_REGISTRATION_STATE_FROM_LOCAL_STORAGE:
      return {
        ...state,
        ...action.payload, // Replace the entire state with the updated values
      };

    default:
      return state;
  }
}
