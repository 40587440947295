import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import { IRegistrationWizardCalculationRequestProps } from "interfaces/IRegistrationWizard";
import CalculatorProvider from "providers/calculatorProvider";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getReadCalculation } from "store/actions/registrationActions";
import { RootState } from "store/reducers/rootReducer";
import ICalculationPriceEmployeeProps, {
  ICalculationPriceEmployeeDispatchProps,
  ICalculationPriceEmployeeStateProps,
} from "./interfaces/ICalculationPriceEmployeeProps";
import ICalculationPriceEmployeeState from "./interfaces/ICalculationPriceEmployeeState";

class CalculationPriceEmployee extends Component<ICalculationPriceEmployeeProps, ICalculationPriceEmployeeState> {
  private timer: NodeJS.Timeout;

  public constructor(props: ICalculationPriceEmployeeProps) {
    super(props);
    this.state = this.defaultState();
  }

  private defaultState(): ICalculationPriceEmployeeState {
    const state: ICalculationPriceEmployeeState = {
      isPriceLoadedOnce: false,
      isPriceUpdated: false,
    };

    return state;
  }

  public componentDidMount(): void {
    // // On refresh get price
    if (this.props.isReadDataCalculationAllowed !== false) {
      this.props.readDataCalculation(this.data);
    }
  }

  public componentDidUpdate(prevProps: Readonly<ICalculationPriceEmployeeProps>): void {
    // get costEmployee when something changes in calculator
    const keyToIgnore = ["isValid"];
    const isEqual: boolean = Object.keys(prevProps.calculator.calculation).every(
      (s) => keyToIgnore.includes(s) || prevProps.calculator.calculation[s] === this.data[s]
    );

    if (!isEqual && this.props.isReadDataCalculationAllowed !== false) {
      this.props.readDataCalculation(this.data);
    }
    // -

    // Do animation price after updated and is loaded once
    if (prevProps.isPriceLoading === true && this.props.isPriceLoading === false) {
      clearTimeout(this.timer);
      if (this.state.isPriceLoadedOnce) {
        this.setState({ isPriceUpdated: true });
        this.timer = setTimeout(() => {
          this.setState({ isPriceUpdated: false });
        }, 450);
      }

      if (!this.state.isPriceLoadedOnce) {
        this.setState({ isPriceLoadedOnce: true });
      }
    }
    // -
  }

  private get data(): IRegistrationWizardCalculationRequestProps {
    return {
      cleaningFrequency: this.props.calculator.calculation.cleaningFrequency,
      houseType: this.props.calculator.calculation.houseType,
      houseSurface: this.props.calculator.calculation.houseSurface,
      includeIroning: this.props.calculator.calculation.includeIroning,
      selectedWeeklyHours: this.props.calculator.calculation.selectedWeeklyHours,
      selectedWeeklyIroningHours: this.props.calculator.calculation.selectedWeeklyIroningHours,
      selectedTimesPerWeek: this.props.calculator.calculation.selectedTimesPerWeek,
      discountCode: this.props.calculator.calculation.discountCode,
      useSelectedWeeklyHoursInCalculation: this.props.calculator.calculation.useSelectedWeeklyHoursInCalculation,
    };
  }

  private toggleSettingsOnMobile(): void {
    if (this.props.toggleSettingsOnMobile) {
      this.props.toggleSettingsOnMobile();
    }
  }

  public render(): JSX.Element {
    return (
      <div className="calculation__price">
        <div className="h1 mb-0 mb-lg-2 d-flex updated_animation">
          {this.props.isPriceLoading && (
            <div className="spinner-border-container">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">
                  {LanguageProvider.t(TranslationMapper.global.loading)}
                  ...
                </span>
              </div>
            </div>
          )}
          {!this.props.isPriceLoading && (
            <span
              className={`d-flex justify-space-between updated_animation__container${
                this.state.isPriceUpdated ? " updated_animation__container--is-updated" : ""
              }`}
              key={this.props.calculation.costEmployee}
            >
              <span>{CalculatorProvider.getValuta(this.props.calculation.costEmployee)}</span>
            </span>
          )}
          <button
            className="btn btn-sm d-lg-none ms-auto calculation__price-toggle-btn"
            type="button"
            onClick={(): void => this.toggleSettingsOnMobile()}
          >
            <div className={`arrow ${this.props.isCalculationSettingsMobileShown ? "arrow--up" : "arrow--down"}`}></div>
          </button>
        </div>

        {this.props.isEveryLongShown && (
          <p className="mb-0">{LanguageProvider.t(TranslationMapper.calculation.employee.every.long)}</p>
        )}

        {!this.props.isEveryLongShown && (
          <>
            <p className="mb-0 d-none d-lg-block">
              {LanguageProvider.t(TranslationMapper.calculation.employee.every.short)}
            </p>
            <p className="mb-0 d-lg-none">{LanguageProvider.t(TranslationMapper.calculation.employee.every.long)}</p>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ICalculationPriceEmployeeStateProps => ({
  calculation: state.registrationState.calculation,
  calculator: {
    ...state.registrationState.calculator,
  },
  isPriceLoading:
    state.loaderState.loaders.includes(LoaderTypes.PriceChecking) ||
    state.loaderState.loaders.includes(LoaderTypes.WeeklyHoursRetrieving),
});

const mapDispatchToProps: ICalculationPriceEmployeeDispatchProps = {
  readDataCalculation: getReadCalculation,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CalculationPriceEmployee));
