enum LoaderTypes {
  AddressChecking = "AddressChecking",
  AddressRegisteredChecking = "AddressRegisteredChecking",
  AccountCreating = "AccountCreating",
  CalculationEmailSending = "CalculationEmailSending",
  CalculatorAdviceChecking = "CalculatorAdviceChecking",
  DiscountCodeValidating = "DiscountCodeValidating",
  InfoSending = "InfoSending",
  PriceChecking = "PriceChecking",
  ShareSending = "ShareSending",
  SubscribeToWaitingListCreating = "SubscribeToWaitingListCreating",
  UnitPriceRetrieving = "UnitPriceRetrieving",
  WeeklyHoursRetrieving = "WeeklyHoursRetrieving",
  ZipCodeChecking = "ZipCodeChecking",
}

export default LoaderTypes;
