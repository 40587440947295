import IconBlouse from "assets/icon-blouse.svg";
import IconCalendar from "assets/icon-calendar.svg";
import IconStopwatch from "assets/icon-stopwatch.svg";
import CalculatorType from "enums/calculatorType";
import TranslationMapper from "i18n/mapper";
import { CleaningFrequency } from "pages/calculator/enums/cleaningFrequency";
import CalculatorProvider from "providers/calculatorProvider";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";
import ICalculationSettingsProps, { ICalculationSettingsStateProps } from "./interfaces/ICalculationSettingsProps";

class CalculationSettings extends Component<ICalculationSettingsProps> {
  private get isDiscountCodeFixedEnabled(): boolean {
    return this.props.discount.calculatorType === CalculatorType.Fixed;
  }

  public renderCleaningFrequency(): JSX.Element {
    return (
      <label className="col-12 form-check-label d-flex justify-content-start align-items-center mb-3">
        <img src={IconCalendar} className="icon icon__md" alt="" />
        <div className="ps-2">
          <p className="mb-0 d-flex align-items-center">
            {this.props.calculator.calculation.cleaningFrequency === CleaningFrequency.Weekly && (
              <>
                {this.props.calculator.calculation.selectedTimesPerWeek}
                {LanguageProvider.t(TranslationMapper.date.weekly_times)}
              </>
            )}
            {this.props.calculator.calculation.cleaningFrequency === CleaningFrequency.Biweekly &&
              LanguageProvider.t(TranslationMapper.date.biweekly)}
            {this.props.calculator.calculation.cleaningFrequency === CleaningFrequency.FourWeekly &&
              LanguageProvider.t(TranslationMapper.date.four_weekly)}
          </p>
        </div>
      </label>
    );
  }

  public renderSelectedWeeklyIroningHours(): JSX.Element {
    return (
      <label className="col-12 form-check-label d-flex justify-content-start align-items-center mb-3">
        <img src={IconBlouse} className="icon icon__md" alt="" />
        <div className="ps-2">
          <p className="mb-0 d-flex align-items-center">
            {this.props.calculator.calculation.selectedWeeklyIroningHours >= 1 && (
              <>
                {CalculatorProvider.getHours(this.props.calculator.calculation.selectedWeeklyIroningHours || 0)}
                {LanguageProvider.t(TranslationMapper.date.time_unit.abbr.hour)}{" "}
              </>
            )}
            {CalculatorProvider.getMinutes(this.props.calculator.calculation.selectedWeeklyIroningHours || 0) !==
              "0" && (
              <>
                {CalculatorProvider.getMinutes(this.props.calculator.calculation.selectedWeeklyIroningHours || 0)}
                {LanguageProvider.t(TranslationMapper.date.time_unit.abbr.minute)}{" "}
              </>
            )}
            {LanguageProvider.t(TranslationMapper.global.ironing).toLowerCase()}
          </p>
        </div>
      </label>
    );
  }

  public renderSelectedWeeklyHours(): JSX.Element {
    return (
      <label className="col-12 form-check-label d-flex justify-content-start align-items-center mb-3">
        <img src={IconStopwatch} className="icon icon__md" alt="" />
        <div className="ps-2">
          <p className="mb-0 d-flex align-items-center">
            {this.props.calculator.calculation.selectedWeeklyHours >= 1 && (
              <>
                {CalculatorProvider.getHours(this.props.calculator.calculation.selectedWeeklyHours)}
                {LanguageProvider.t(TranslationMapper.date.time_unit.abbr.hour)}{" "}
              </>
            )}
            {CalculatorProvider.getMinutes(this.props.calculator.calculation.selectedWeeklyHours || 0) !== "0" && (
              <>
                {CalculatorProvider.getMinutes(this.props.calculator.calculation.selectedWeeklyHours)}
                {LanguageProvider.t(TranslationMapper.date.time_unit.abbr.minute)}{" "}
              </>
            )}
            {LanguageProvider.t(TranslationMapper.global.cleaning).toLowerCase()}
          </p>
        </div>
      </label>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {this.renderCleaningFrequency()}
        {!this.isDiscountCodeFixedEnabled && this.renderSelectedWeeklyHours()}
        {this.props.calculator.calculation.includeIroning && this.renderSelectedWeeklyIroningHours()}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): ICalculationSettingsStateProps => ({
  calculator: {
    ...state.registrationState.calculator,
  },
  discount: state.registrationState.calculator.discount,
});

export default connect(mapStateToProps)(withTranslation()(CalculationSettings));
