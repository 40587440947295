import AccountClientAuthorized from "clients/accountClientAuthorized";
import IAccountRegistrationUserRequest from "components/account/interfaces/IAccountRegistrationUserRequest";
import { ICalculatorAdviceRequestProps } from "components/calculatorAdvice/interfaces/ICalculatorAdviceProps";
import { ISendCalculationEmailRequestProps } from "components/sendCalculationEmail/interfaces/ISendCalculationEmailProps";
import { IShareRequestProps } from "components/share/interfaces/IShareState";
import { ISubscribeToWaitingListRequestProps } from "components/subscribeToWaitingList/interfaces/ISubscribeToWaitingListProps";
import IAccountRegistrationResponse from "interfaces/IAccountRegistration";
import { IErrorResponse } from "interfaces/IErrorResponse";
import {
  IRegistrationWizardAddressRequestProps,
  IRegistrationWizardCalculationRequestProps,
  IRegistrationWizardCalculationResponseProps,
  IRegistrationWizardRegisterRequestProps,
} from "interfaces/IRegistrationWizard";
import { IMinHoursFrequency } from "pages/calculator/interfaces/IMinHoursFrequency";
import {
  IValidateDiscountCodeRequestProps,
  IValidateDiscountCodeResponse,
} from "pages/discount/interfaces/IDiscountProps";
import AccountClient from "../clients/accountClient";

export default class AccountService {
  private readonly accountClient: AccountClient = new AccountClient();
  private readonly authorizedAccountClient: AccountClientAuthorized = new AccountClientAuthorized();

  public async addNewUserToRegistrationQueueAsync(
    registrationRequest: IRegistrationWizardRegisterRequestProps
  ): Promise<IErrorResponse> {
    return this.accountClient.addNewUserToRegistrationQueueAsync(registrationRequest);
  }

  public async calculateAsync(
    calculationRequest: IRegistrationWizardCalculationRequestProps
  ): Promise<IRegistrationWizardCalculationResponseProps> {
    return this.accountClient.calculateAsync(calculationRequest);
  }

  public async caluculatorAdvicecalculateAsync(
    calculationRequest: ICalculatorAdviceRequestProps
  ): Promise<IRegistrationWizardCalculationResponseProps> {
    return this.accountClient.calculatorAdviceCalculateAsync(calculationRequest);
  }

  public async getAccountRegistrationRequest(
    accountRegistrationRequestId: string
  ): Promise<IAccountRegistrationResponse> {
    return this.accountClient.getAccountRegistrationRequest(accountRegistrationRequestId);
  }

  public async getAddressAsync(zipCode: string, houseNumber: number): Promise<IRegistrationWizardAddressRequestProps> {
    return this.accountClient.getAddressAsync(zipCode, houseNumber);
  }

  public async getAddressAlreadyRegisteredAsync(
    houseNumber: number,
    zipCode: string,
    houseNumberAddition?: string
  ): Promise<boolean> {
    return this.accountClient.getAddressAlreadyRegisteredAsync(zipCode, houseNumber, houseNumberAddition);
  }

  public async getCheckActiveRegionAsync(zipCode: string): Promise<boolean> {
    return this.accountClient.getCheckActiveRegionAsync(zipCode);
  }

  public async getMinimumHoursPerFrequencyAsync(): Promise<IMinHoursFrequency[]> {
    return this.accountClient.getMinimumHoursPerFrequencyAsync();
  }

  public async registerAccountRegistrationUserAsync(
    accountRegistrationRequest: IAccountRegistrationUserRequest
  ): Promise<Response> {
    return this.authorizedAccountClient.registerAccountRegistrationUserAsync(accountRegistrationRequest);
  }

  public async shareCalculationAsync(shareCalculationRequest: IShareRequestProps): Promise<void> {
    return this.accountClient.shareCalculationAsync(shareCalculationRequest);
  }

  public async sendCalulationMailAsync(sendCalculationEmailRequest: ISendCalculationEmailRequestProps): Promise<void> {
    return this.accountClient.sendCalulationMailAsync(sendCalculationEmailRequest);
  }

  public async subscribeToWaitingListAsync(
    subscribeToWaitingListRequest: ISubscribeToWaitingListRequestProps
  ): Promise<void> {
    return this.accountClient.subscribeToWaitingListAsync(subscribeToWaitingListRequest);
  }

  public async validateDiscountCodeAsync(
    validateDiscountCodeRequest: IValidateDiscountCodeRequestProps
  ): Promise<IValidateDiscountCodeResponse> {
    return this.accountClient.validateDiscountCodeAsync(validateDiscountCodeRequest);
  }
}
