import { CleaningFrequency } from "pages/calculator/enums/cleaningFrequency";
import { IMinHoursFrequency } from "pages/calculator/interfaces/IMinHoursFrequency";
import DateUtils from "utils/dateUtils";

export default class CalculatorProvider {
  /* eslint-disable @typescript-eslint/no-inferrable-types */
  public static step: number = 0.25;
  public static durationDefault: number = 0;
  public static durationMax: number = 40;
  public static durationMin: number = 0;
  public static durationIroningMin: number = 0.25;
  public static durationIroningDefault: number = 0.5;
  public static durationTimesPerWeekMax: number = 5;
  public static durationTimesPerWeekMin: number = 1;
  public static timesPerWeekDefault: number = 1;
  /* eslint-enable @typescript-eslint/no-inferrable-types */

  public static setDurationMin(cleaningFrequency: CleaningFrequency, minimumPerFrequency: IMinHoursFrequency[]): void {
    const frequency = minimumPerFrequency.find(
      (freq) => freq.frequencyType === cleaningFrequency
    ) as IMinHoursFrequency;
    this.durationMin = frequency ? frequency.numberOfHoursMinimal : 0;
  }

  public static getHours(duration: number): string {
    return DateUtils.getHourFromDecimalTime(duration ?? 0);
  }

  public static getMinutes(duration: number): string {
    return DateUtils.getMinutesFromDecimalTime(duration ?? 0);
  }

  public static getValuta(input?: number): string {
    if (!input) {
      input = 0;
    }

    const formatter = new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    });

    return formatter.format(input).replace(/\D00(?=\D*$)/, ",-");
  }
}
